// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.session-table {
    width: 100%;
    border-collapse: collapse;
  }
  
  .session-table th, .session-table td {
    border: 1px solid #ddd;
    padding: 8px;
  }
  
  .session-table th {
    background-color: #f2f2f2;
    text-align: left;
  }
  `, "",{"version":3,"sources":["webpack://./src/components/Session.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,yBAAyB;EAC3B;;EAEA;IACE,sBAAsB;IACtB,YAAY;EACd;;EAEA;IACE,yBAAyB;IACzB,gBAAgB;EAClB","sourcesContent":[".session-table {\n    width: 100%;\n    border-collapse: collapse;\n  }\n  \n  .session-table th, .session-table td {\n    border: 1px solid #ddd;\n    padding: 8px;\n  }\n  \n  .session-table th {\n    background-color: #f2f2f2;\n    text-align: left;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
